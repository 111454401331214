<template>
  <component :is="dynamicComponent" :boardRef="boardRef" :entity-id="entityId" />
</template>

<script>
import { GroupDealPageWidget, HomeDealPageWidget, IndividualDealPageWidget } from '../widgets'

export default {
  name: 'DealPage',
  components: {
    IndividualDealPageWidget,
    GroupDealPageWidget,
    HomeDealPageWidget
  },
  props: {
    entityId: { type: [Number, String], required: true },
    boardRef: { type: String, required: true }
  },
  computed: {
    dynamicComponent() {
      // TODO: use a more reliable marker than the board ID
      switch (this.boardRef) {
        case 'life-group':
          return 'GroupDealPageWidget'
          break
        case 'home':
          return 'HomeDealPageWidget'
          break
        default:
          return 'IndividualDealPageWidget'
          break
      }
    }
  }
}
</script>

<style scoped>
.tools__area {
  min-height: 200px;
}
</style>

<i18n>
{
  "en": {
    "and": " and "
  },
  "fr": {
    "and": " et "
  }
}
</i18n>
